<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <navigation-component :id="3" />
      <div class="page-row">
        <img class="bg" src="../../assets/oss/pc_about_pg_bg.jpg" />
        <div class="content">
          <img class="bg" src="../../assets/oss/pc_about_content_bg.png" style="width: 100%; height: 100%;" />
          <div v-for="(item, index) in questionList" :key="index" class="item" :class="showIndex === index ? 'active' : ''" @click="handleShowDetail(index)">
            <div class="title" data-aos="fade-up" :data-aos-delay="index*100 + 100">
              {{ item.title }}
              <img src="../../assets/images/pc/arrow-icon.png" class="arrow" />
            </div>
            <div class="detail" v-if="showIndex === index">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <footer-component />
    </div>
  </div>
</template>

<script>
import { cjQuestionList } from '@/api/index'
import NavigationComponent from '../../components/NavigationComponent.vue'
import FooterComponent from '../../components/FooterComponent.vue'
import Loading from '../../components/Loading.vue'
import { checkImg } from '../../utils/checkImg'

export default {
  name: 'Question',
  metaInfo: {
    title: '常见问题 - 偶遇圈',
    meta: [
      {
        name: 'description',
        content: '偶遇圈app，是一个为单身男女提供，附近高颜值、高素质的真人交友平台！'
      },
      {
        name: 'keywords',
        content: '偶遇、艳遇、邂逅、偶遇圈、偶遇圈app、偶遇圈下载、同城交友、附近约会'
      }
  ]
  },
  components: {
    NavigationComponent,
    FooterComponent,
    Loading
  },
  data () {
    return {
      showIndex: '',
      questionList: [],
      pageLoading: true
    }
  },
  mounted () {
    checkImg(() => {
      this.pageLoading = false
    })
    this.handleGetCjQuestionData()
  },
  methods: {
    handleGetCjQuestionData () {
      cjQuestionList().then(res => {
        console.log(res)
        this.questionList = res.info
      })
    },
    handleShowDetail (index) {
      const { showIndex } = this
      if (showIndex === index) {
        this.showIndex = ''
      } else {
        this.showIndex = index
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1099px;
  /*min-height: 100vh;*/
  .content {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 1250px;
    height: 766px;
    padding: 42px 30px;
    overflow-y: auto;
    .item {
      position: relative;
      z-index: 1;
      margin-bottom: 13px;
      background-color: rgba(0, 0, 0, .2);
      padding: 0 40px;
      cursor: pointer;
      &.active {
        background-color: rgba(255, 255, 255, .2);
        .title {
          color: #fce302;
          .arrow {
            transform: rotate(180deg);
          }
        }
      }
      .title {
        line-height: 48px;
        font-size: 17px;
        color: #fefefe;
        padding-right: 24px;
        position: relative;
        .arrow {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 17px;
          transition: all .3s;
        }
      }
      .detail {
        font-size: 15px;
        color: #fff;
        line-height: 30px;
        padding: 18px 34px 34px;
      }
    }
  }
}
</style>
